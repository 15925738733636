document.addEventListener("DOMContentLoaded", function() {
    const heroElement = document.createElement('section');
    heroElement.className = 'hero';
    heroElement.innerHTML = `
      <div class="hero-content">
      <h1><mark>BIMBEL SCIENTISTS</mark></h1>
      <h1><mark>WA 0856 8652 8913</mark></h1>
      
<h1>BIMBEL ; KURSUS & JASA PEMBUATAN WEBSITE MENGGUNAKAN JAVASCRIPT</h1>
        
<h1>LANGSUNG SAJA MENDAFTAR DI <cite>WA 0858 8652 8913</cite></h1> 

      </div>
    `;
    document.getElementById('app').appendChild(heroElement);
  });
  
  
  